// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dr_BJ";
export var servicesToolkitSection__card = "dr_BK";
export var servicesToolkitSection__icons = "dr_BL";
export var servicesToolkitSection__serviceToolkitIcon1 = "dr_BN";
export var servicesToolkitSection__serviceToolkitIcon10 = "dr_BY";
export var servicesToolkitSection__serviceToolkitIcon11 = "dr_BZ";
export var servicesToolkitSection__serviceToolkitIcon12 = "dr_B0";
export var servicesToolkitSection__serviceToolkitIcon13 = "dr_B1";
export var servicesToolkitSection__serviceToolkitIcon14 = "dr_B2";
export var servicesToolkitSection__serviceToolkitIcon15 = "dr_B3";
export var servicesToolkitSection__serviceToolkitIcon16 = "dr_B4";
export var servicesToolkitSection__serviceToolkitIcon17 = "dr_B5";
export var servicesToolkitSection__serviceToolkitIcon18 = "dr_B6";
export var servicesToolkitSection__serviceToolkitIcon19 = "dr_B7";
export var servicesToolkitSection__serviceToolkitIcon2 = "dr_BP";
export var servicesToolkitSection__serviceToolkitIcon20 = "dr_B8";
export var servicesToolkitSection__serviceToolkitIcon3 = "dr_BQ";
export var servicesToolkitSection__serviceToolkitIcon4 = "dr_BR";
export var servicesToolkitSection__serviceToolkitIcon5 = "dr_BS";
export var servicesToolkitSection__serviceToolkitIcon6 = "dr_BT";
export var servicesToolkitSection__serviceToolkitIcon7 = "dr_BV";
export var servicesToolkitSection__serviceToolkitIcon8 = "dr_BW";
export var servicesToolkitSection__serviceToolkitIcon9 = "dr_BX";
export var servicesToolkitSection__sprite = "dr_BM";
export var servicesToolkitSection__title = "dr_B9";